const initialState = {
  result: null,
  isSearching: false,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCHING_DOG":
      console.log("searchReducer:SEARCHING_DOG");
      return {
        ...state,
        isSearching: true,
      };
    case "SEARCH_RESULT":
      return {
        ...state,
        isSearching: false,
        result: searchResultMapper(action.value),
      };
    default:
      return state;
  }
};

const searchResultMapper = (result) =>
  result.map((r) => {
    console.log("searchResultMapper", r);
    return {
      // Dog
      name: r.hundens_Namn,
      regnr: r.regnummer,
      race: r.rasnamn,
      sex: r.kön,
      birthdate: r.födelsedatum,
      champ: r.champ,

      // Father
      fatherName: r.fader,
      fatherChamp: r.fader_Champ,

      // Mother
      motherName: r.moder,
      motherChamp: r.moder_Champ,

      // Owner
      owner: r.ägare,
    };
  });

export default searchReducer;
