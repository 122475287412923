const initialState = {
  firstname: null,
  lastname: null,
  address: null,
  postal: null,
  city: null,
  email: null,
  homepage: null,
  profileLoaded: false,
  profileLoadedError: false,
};

const profileReducer = (state = initialState, action) => {
  const { value, type } = action;
  switch (type) {
    case "PROFILE_LOADED":
      return {
        ...state,
        profileLoaded: value,
      };
    case "GET_PROFILE":
      console.log("GET_PROFILE", state);
      if (value.status && value.status >= 400) {
        return {
          ...state,
          profileLoadedError: true,
        };
      }

      return {
        ...state,
        profileLoaded: true,
        ...value,
      };

    default:
      return state;
  }
};

export default profileReducer;
