import * as React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import "./Login.css";

const Login = () => (
  <div className="lg:flex lg:justify-center sm:px-24">
    <div className="lg:w-96 ">
      <div className="lg:justify-center lg:mt-40 ">
        <div className="rounded-lg p-8 bg-white">
          <h2
            className="flex justify-center items-center  align-center text-center text-2xl text-blue-900 font-display font-semibold  xl:text-2xl
                    xl:text-bold"
          >
            <img
              src="https://www.hittaalghund.se/bilder/s%C3%A4k_logo_63x80.png"
              className="rounded-md m-1 p-0 h-14"
            />
            <span className="px-4 text-xl text-blue-400">MinHund</span>
          </h2>
          <div className="mt-12">
            <div className="text-center">Välkommen till MinHund-portalen.</div>
            <div className="mt-10 flex place-content-center">
              <a
                href="/api/login"
                className=" px-8 w-1/2 text-center bg-blue-500 text-gray-100 p-4  rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-blue-600                      shadow-lg"
              >
                Logga in
              </a>
            </div>

            <div className="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
              Problem att logga in? Kontakta{" "}
              <a
                className="cursor-pointer text-blue-600 hover:text-blue-800"
                href="mailto:support@hittaalghund.se"
              >
                kundtjänst
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default connect()(Login);
