import {
  applyMiddleware,
  combineReducers,
  configureStore,
  createStore,
} from "@reduxjs/toolkit";

import { connectRouter } from "connected-react-router";
import profileReducer from "./reducers/profile";
import thunk from "redux-thunk";
import searchReducer from "./reducers/search";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import dogReducer from "./reducers/dog";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = (history) =>
  combineReducers({
    //login: loginReducer,
    router: connectRouter(history),
    search: searchReducer,
    profile: profileReducer,
    dog: dogReducer,
  });

/*export default (history) =>
  configureStore(
    {
      reducer: rootReducer(history),
    },
    applyMiddleware(thunk)
  );
*/

export default (history) => {
  //const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const store = createStore(rootReducer(history), applyMiddleware(thunk));
  //const persistor = persistStore(store);
  return { store };
};
