import * as React from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { DateTime } from "luxon";
import {
  getDogAsync,
  searchDogAsync,
  takeOwnershipAsync,
} from "store/actions/actions";
import { ListItemContainer } from "fragments/ListItem";
import { ListItem } from "fragments/ListItem";
import { Button } from "fragments/Button";

const Dog = (props) => {
  const { id: idEncoded } = useParams();
  const id = decodeURIComponent(idEncoded);

  // Find the doggo
  React.useEffect(() => {
    props.get(idEncoded);
  }, [idEncoded]);

  const dog = props.result;

  if (dog && !dog.loaded) {
    return <div />;
  }

  const bdate = DateTime.fromISO(dog.birthdate).setLocale("sv");
  const formattedBirthDate = bdate.toLocaleString({
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const takeOwnership = () => {
    props.takeOwnership(id);
  };

  //const bdate = new Date(dog.birthdate);

  const fatherLink = (
    <Link
      className="underline hover:no-underline text-indigo-800"
      to={"/dog/" + encodeURIComponent(dog.fatherId)}
    >
      {dog.fatherName}
    </Link>
  );
  const motherLink = (
    <Link
      className="underline hover:no-underline text-indigo-800"
      to={"/dog/" + encodeURIComponent(dog.motherId)}
    >
      {dog.motherName}
    </Link>
  );

  const { profile } = props;

  if (!profile.profileLoaded) {
    return <div />;
  }

  const openPage = (url) => {
    window.location.href = url;
  };

  return (
    <div className="flex space-x-12">
      <div className="w-3/4 ">
        <h2 className="mb-2 text-xl font-medium">Hundinformation</h2>
        <div className="py-4 px-8 rounded-lg bg-white ">
          <div tag="h5" className="flex pb-4 ">
            <div className="w-1/2">{dog.name}</div>
            <div className="w-1/2 text-right">
              <small>{dog.id}</small>
            </div>
          </div>

          <div className="">
            <div className="flex space-x-12">
              <div className="w-1/2">
                <ListItem title="Född" value={formattedBirthDate} />
                <ListItem title="Ras" value={dog.raceName} />
                <ListItem
                  title="Kön"
                  value={dog.sex.toLowerCase() === "h" ? "hane" : "tik"}
                />
              </div>

              <div className="w-1/2">
                <ListItem title="Moder" value={dog.motherId && motherLink} />
                <ListItem title="Fader" value={dog.fatherId && fatherLink} />
                <ListItem
                  title="Ägarstatus"
                  value={dog.hasOwner ? "Har en ägare" : "Saknar ägare"}
                />
              </div>
            </div>
          </div>
        </div>
        {/*<Button className="pt-4" type="primary" onClick={takeOwnership}>
          Ta ägarskap
  </Button>*/}
      </div>
      <div className="w-1/4">
        <h2 className="mb-2 text-xl font-medium">Åtgärder</h2>
        {profile && profile.hasActivePayment && (
          <>
            <Button
              className="pt-4"
              type="primary"
              onClick={(e) => openPage(profile.clubData.exhibitionURL)}
            >
              Anmäl till utställning
            </Button>
            <Button
              className="pt-2"
              type="primary"
              onClick={(e) => openPage(profile.clubData.hunterTestURL)}
            >
              Anmäl till jaktprov
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const state = (state) => {
  console.log(state);
  return {
    result: state.dog.selectedDog,
    profile: state.profile,
  };
};

const dispatch = (dispatch) => {
  return {
    get: (id) => dispatch(getDogAsync(id)),
    search: (query) => dispatch(searchDogAsync(query)),
    takeOwnership: (id) => dispatch(takeOwnershipAsync(id)),
  };
};

export default connect(state, dispatch)(Dog);
