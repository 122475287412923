import { Button } from "fragments/Button";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getOwnedDogsAsync, getProfileAsync } from "store/actions/actions";
import Dogs from "./Dogs";
import "./Home.css";
import Payments from "./Payments";
import Profile from "./Profile";

const Home = (props) => {
  return (
    <div>
      <div className="md:flex w-full md:space-x-4">
        <div className="md:w-2/3">
          <Profile />
        </div>
        <div className="md:w-1/3 mt-8 md:mt-0">
          <Payments />
        </div>
      </div>
      <div className="mt-8 ">
        <Dogs />
      </div>
    </div>
  );
};

const state = (state) => {
  console.log("state", state);

  return {
    ownedDogs: state.dog.ownedDogs,
    result: state.search.result,
    profile: state.profile,
  };
};

const dispatch = (dispatch) => {
  return {
    getOwnedDogs: () => dispatch(getOwnedDogsAsync()),
    getProfile: () => dispatch(getProfileAsync()),
  };
};

export default connect(state, dispatch)(Home);
