import * as React from "react";

export const ListItemContainer = (props) => {
  return <dl className="flex w-full pb-2">{props.children}</dl>;
};

export const ListItem = (props) => {
  if (!props.title) {
    throw "missing title";
  }
  if (!props.value) {
    throw "missing value";
  }

  return (
    <dl className="flex w-full pb-2">
      <dt className="text-left text-gray-400 w-1/2">{props.title}</dt>
      <dd className="text-right w-1/2">{props.value}</dd>
    </dl>
  );
};
