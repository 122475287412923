const initialState = {
  selectedDog: {
    loaded: false,
  },
  ownedDogs: [],
};

const dogReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DOG":
      return {
        ...state,
        selectedDog: { ...action.value, loaded: true },
      };

    case "GET_OWNED_DOGS":
      console.log("GET OWNED DOGS");
      return {
        ...state,
        ownedDogs: action.value,
      };

    default:
      return state;
  }
};

export default dogReducer;
