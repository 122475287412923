import { Button } from "fragments/Button";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  searchDog,
  searchDogAsync,
  takeOwnershipAsync,
} from "store/actions/actions";

const SearchDog = (props) => {
  // We might already have a query
  const search = new URLSearchParams(props.location.search);

  const searchQuery = search.get("query");

  const [query, setQuery] = React.useState("");

  const [searchState, setSearchState] = useState("");
  const history = useHistory();

  const submitHandler = (evt, b) => {
    evt.preventDefault();
    //console.log(searchState);
    //props.search(searchState);
    if (searchState !== null) {
      history.push("/search-dog?query=" + searchState);
    }
  };

  const handleSearchChange = (evt) => {
    setSearchState(evt.currentTarget.value);
  };

  console.log("owned dogs", searchQuery && searchQuery != query);

  useEffect(() => {
    if (searchQuery !== null && searchQuery !== query) {
      console.log("doing a search", searchQuery, query);
      setQuery(searchQuery);
      props.search(searchQuery);
    }
  }, [searchQuery, query]);

  const newResult = props.result.map((r) => {
    console.log("res", r, props.ownedDogs);
    const owned = props.ownedDogs.some((o) => {
      console.log(o.Id, r.regnr);
      return o.Id === r.regnr;
    });
    return {
      ...r,
      ownedByYou: owned,
    };
  });

  console.log(newResult);

  return (
    <div>
      <div className="md">
        <div className="mb-5 flex">
          <form onSubmit={submitHandler}>
            <div className="flex rounded border-2 border-gray-300 ">
              <input
                type="text"
                onChange={handleSearchChange}
                className=" px-4 py-2 w-full text-black-800 border-transparent focus:border-transparent focus:ring-0"
                placeholder="Sök hund"
              />
              <button className="flex items-center justify-center px-4 bg-white">
                <svg
                  className="w-6 h-6 text-gray-600 "
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>

      <div>
        {props.result.length === 0 && searchState === "" && !props.isSearching && (
          <>
            <h1 className="text-xl pb-2">Hundsök</h1>
            <p>Skriv något i sökrutan ovan för att börja söka efter hundar!</p>
          </>
        )}

        {searchState !== "" && props.isSearching && (
          <>
            <p>Söker</p>
          </>
        )}

        {newResult.length > 0 && !props.isSearching && (
          <table className="w-full rounded-lg bg-white divide-y divide-gray-300">
            <thead className="bg-gray-900">
              <tr className="text-white text-left">
                <th className="font-semibold uppercase text-left text-sm px-4 py-2 md:px-8 md:py-4">
                  Namn
                </th>
                <th className="font-semibold uppercase text-left text-sm px-4 py-2 md:px-8 md:py-4">
                  Kön
                </th>
                {/*<th className="font-semibold uppercase text-left text-sm px-4 py-2 md:px-8 md:py-4">
                  Fader
                </th>
                <th className="font-semibold uppercase text-left text-sm px-4 py-2 md:px-8 md:py-4">
                  Moder
        </th>*/}
                <th className="font-semibold uppercase text-left text-sm px-4 py-2 md:px-8 md:py-4">
                  Ägare
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {newResult.map((r, i) => {
                let fatherName = r.fatherName;
                if (!r.fatherName || r.fatherName.length === 0) {
                  fatherName = <span className="italic">Okänd</span>;
                }

                let motherName = r.motherName;
                if (!r.motherName || r.motherName.length === 0) {
                  motherName = <span className="italic">Okänd</span>;
                }

                let owner = r.owner;
                if (!r.owner || r.owner.length === 0) {
                  owner = <span className="italic">Okänd</span>;
                }
                if (r.ownedByYou) {
                  owner = "Du";
                }

                return (
                  <tr key={i}>
                    <td className="px-4 py-2 md:px-8 md:py-4">
                      {" "}
                      <Link to={"/dog/" + encodeURIComponent(r.regnr)}>
                        {" "}
                        {r.name ?? "Namn okänt"}
                      </Link>
                    </td>
                    <td className="px-4 py-2 md:px-8 md:py-4">
                      {r.sex ?? "Kön okänt"}
                    </td>
                    {/*<td className="px-4 py-2 md:px-8 md:py-4">{fatherName}</td>
                    <td className="px-4 py-2 md:px-8 md:py-4">{motherName}</td>*/}
                    <td className="px-4 py-2 md:px-8 md:py-4">
                      {}
                      {owner}{" "}
                      {!r.ownedByYou && (
                        <Button
                          size="md"
                          onClick={(e) => {
                            if (
                              confirm("Vill du ta ägarskap över denna hund?")
                            ) {
                              props.takeOwnership(r.regnr);
                            }
                          }}
                        >
                          Ta ägarskap
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
  //}
};

const mapStateToProps = (state) => {
  return {
    isSearching: state.search.isSearching,
    result: state.search.result || [],
    ownedDogs: state.dog.ownedDogs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    search: (query) => dispatch(searchDogAsync(query)),
    takeOwnership: (id) => dispatch(takeOwnershipAsync(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchDog);
