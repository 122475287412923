import * as React from "react";
import { Route } from "react-router";
import { Redirect, Switch } from "react-router-dom";
import Layout from "./components/Portal/Layout";

import Login from "./components/Login/Login";

import "./custom.css";
import Home from "./components/Portal/Home/Home";
import SearchDog from "./components/Portal/SearchDog/SearchDog";
import Dog from "./components/Portal/Dog/Dog";
import Profile from "components/Portal/Profile/Profile";

const App = () => (
  <Switch>
    <Route exact path="/logged-out">
      <div>youre logged out</div>
    </Route>

    <Route exact path="/">
      <Redirect to="/home" />
    </Route>
    <Route path="/login" component={Login} />

    {/* authed routes */}
    <Layout>
      <Route exact path="/home" component={Home} />
      <Route exact path="/profile" component={Profile} />
      <Route path="/search-dog" component={SearchDog} />
      <Route path="/dog/:id" component={Dog} />
    </Layout>
  </Switch>
);

export default App;
