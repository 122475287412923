import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import "./NavMenu.css";

import { connect } from "react-redux";

const NavMenu = (props) => {
  // props.search("Laika");
  const [searchState, setSearchState] = useState("");
  const history = useHistory();

  const submitHandler = (evt, b) => {
    evt.preventDefault();
    //console.log(searchState);
    //props.search(searchState);

    history.push("/search-dog?query=" + searchState);
  };

  const handleSearchChange = (evt) => {
    setSearchState(evt.currentTarget.value);
  };

  return (
    <nav className="p-1 mt-0  w-full top-0">
      <div className="container mx-auto flex flex-wrap items-center">
        <div className="flex w-full  text-white">
          <ul className="list-reset w-full flex  items-center">
            <li className="mr-3">
              <img
                src="https://www.hittaalghund.se/bilder/s%C3%A4k_logo_63x80.png"
                className="rounded-md m-1 p-0 h-14"
              />
            </li>
            <li className="sm:mr-10 justify-self-start">
              <Link to="/" className="sm:text-xl text-blue-400">
                Min Hund
              </Link>
            </li>
            <li className="sm:mr-3">
              <Link
                to="/"
                className="inline-block py-2 px-4 text-gray-600 no-underline font-semibold"
              >
                Hem
              </Link>
            </li>
            <li className="sm:mr-3">
              <Link
                to="/search-dog"
                className="inline-block py-2 px-4 text-gray-600 no-underline font-semibold"
              >
                Sök hund
              </Link>
            </li>
            <li className="sm:mr-3 justify-self-end">
              <a
                href="/logout"
                className="inline-block py-2 px-4 text-gray-600 no-underline font-semibold"
              >
                Logga ut
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default connect()(NavMenu);
