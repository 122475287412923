const fetchJSON = async (url, params = {}) => {
  params.headers = {
    ...params.headers,
    "content-type": "application/json",
  };

  const resp = await fetch("/api/" + url, params);

  if (resp.status === 401) {
    window.location.href = "/login";
  }

  return resp.json();
};

const fetchPlain = async (url, params = {}) => {
  params.headers = {
    ...params.headers,
    "content-type": "application/json",
  };

  const resp = await fetch("/api/" + url, params);

  if (resp.status === 401) {
    window.location.href = "/login";
  }

  return resp;
};

export const loadProfile = async () => {
  return async (dispatch) => {
    const resp = await fetchJSON("/profile", {});

    dispatch({
      type: "PROFILE_PAYLOAD",
      value: await resp,
    });
  };
};

export const searchDog = (query) => {
  return {
    type: "SEARCH_DOG",
    value: query,
  };
};

export const searchDogAsync = (query) => {
  return async (dispatch) => {
    dispatch({
      type: "SEARCHING_DOG",
    });

    const resp = await fetchJSON("dog/search-dog?query=" + query);
    dispatch({
      type: "SEARCH_RESULT",
      value: resp,
    });
  };
};

export const saveProfileAsync = (data) => {
  return async (dispatch) => {
    const resp = await fetchJSON("profile", {
      method: "POST",
      body: JSON.stringify(data),
    });
    dispatch({
      type: "SAVE_PROFILE",
      value: resp,
    });
  };
};

export const getProfileAsync = (data) => {
  console.log("getProfileAsync");
  return async (dispatch) => {
    dispatch({
      type: "PROFILE_LOADED",
      value: false,
    });

    const resp = await fetchJSON("profile");
    dispatch({
      type: "GET_PROFILE",
      value: resp,
    });
  };
};

export const getDogAsync = (id) => {
  return async (dispatch) => {
    const resp = await fetchJSON("dog/" + id);
    dispatch({
      type: "GET_DOG",
      value: resp,
    });
  };
};

export const getOwnedDogsAsync = () => {
  return async (dispatch) => {
    const resp = await fetchJSON("dog/owned-dogs");
    dispatch({
      type: "GET_OWNED_DOGS",
      value: resp,
    });
  };
};

export const takeOwnershipAsync = (id) => {
  return async (dispatch) => {
    const resp = await fetchPlain(
      "dog/" + encodeURIComponent(id) + "/take-ownership",
      {
        method: "POST",
        body: JSON.stringify({ sup: true }),
      }
    );

    dispatch(getOwnedDogsAsync());
    dispatch({
      type: "TAKE_OWNERSHIP",
      value: resp,
    });
  };
};
