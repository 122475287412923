import { Button } from "fragments/Button";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getOwnedDogsAsync, getProfileAsync } from "store/actions/actions";
import "./Home.css";

const Profile = (props) => {
  React.useEffect(() => {
    props.getProfile();
  }, []);

  const { profile } = props;

  if (!profile.profileLoaded) {
    return <div />;
  }

  return (
    <>
      <h2 className="mb-2 text-xl font-medium">Min profil</h2>
      <div className=" w-full rounded-lg bg-white p-4">
        <div className="">
          <div className="text-xl">
            <span className="font-medium">{profile.name}</span>{" "}
            <span className="text-base"># {profile.memberNumber}</span>
          </div>
          <div className="flex pt-4">
            <div className="w-1/2">
              <div className="text-sm text-gray-600">Address</div>
              <div> {profile.postalAddress} </div>
              <div>
                {profile.postalCode} {profile.city}
              </div>
              <div className="text-sm text-gray-600 mt-4">Kontaktuppgifter</div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z"
                    clipRule="evenodd"
                  />
                </svg>
                {profile.email}
              </div>
              <div>
                {profile.mobilePhone && (
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 inline mr-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    {profile.mobilePhone}
                  </span>
                )}
              </div>
              <div>
                {profile.homePhone && (
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 inline mr-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    {profile.homePhone}
                  </span>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-sm text-gray-600">Klubb</div>
              <div> {profile.clubData.name} </div>
              <div className="text-sm text-gray-600 mt-4">Medlemsnr</div>
              <div> {profile.memberNumber} </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const state = (state) => {
  return {
    ownedDogs: state.dog.ownedDogs,
    result: state.search.result,
    profile: state.profile,
  };
};

const dispatch = (dispatch) => {
  return {
    getOwnedDogs: () => dispatch(getOwnedDogsAsync()),
    getProfile: () => dispatch(getProfileAsync()),
  };
};

export default connect(state, dispatch)(Profile);
