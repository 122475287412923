import InfoBox from "components/InfoBox";
import * as React from "react";

import NavMenu from "./NavMenu";

const Layout = (props) => {
  return (
    <React.Fragment>
      <NavMenu />

      <div className="container mx-auto pl-5 pr-5 md:pl-0 md:pr-0">
        {/*<InfoBox />*/}
        <div className="w-full pt-8 pb-8">{props.children}</div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
