import { format } from "date-fns";
import { formatWithOptions } from "date-fns/fp";
import { sv } from "date-fns/locale";
import { Button } from "fragments/Button";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getOwnedDogsAsync, getProfileAsync } from "store/actions/actions";
import "./Home.css";

const Payments = (props) => {
  React.useEffect(() => {
    props.getProfile();
  }, []);

  const { profile } = props;

  if (!profile.profileLoaded) {
    return <div />;
  }

  return (
    <>
      <h2 className="mb-2 text-xl font-medium">Mina betalningar</h2>
      <div className=" w-full rounded-lg bg-white p-4">
        {!profile.payments ||
          (profile.payments && profile.payments.length === 0 && (
            <div>Inga betalningar registrerade</div>
          ))}
        {profile.payments && profile.payments.length > 0 && (
          <table>
            <thead>
              <tr>
                <th className="text-left">År</th>
                <th>Betalningsdatum</th>
              </tr>
            </thead>
            <tbody>
              {profile.payments.map((p, i) => {
                const fmt = formatWithOptions({ locale: sv }, "yyyy-MM-dd");
                var css = "";

                if (i != profile.payments.length - 1) {
                  css += "border-b";
                }
                return (
                  <tr key={i} className={css}>
                    <td className="w-2/3 pt-2 pb-2">{p.year}</td>
                    <td className="w-1/3 pt-2 pb-2 text-right">
                      {fmt(new Date(p.payed))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

const state = (state) => {
  return {
    ownedDogs: state.dog.ownedDogs,
    result: state.search.result,
    profile: state.profile,
  };
};

const dispatch = (dispatch) => {
  return {
    getOwnedDogs: () => dispatch(getOwnedDogsAsync()),
    getProfile: () => dispatch(getProfileAsync()),
  };
};

export default connect(state, dispatch)(Payments);
