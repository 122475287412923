import { Button } from "fragments/Button";
import * as React from "react";
import { connect } from "react-redux";
import { getProfileAsync, saveProfileAsync } from "store/actions/actions";

const Profile = (props) => {
  const [formState, setFormState] = React.useState(props.profile);

  const setFormStateHelper = (property, value) => {
    const newState = {
      ...formState,
      [property]: value,
    };

    setFormState(newState);
  };

  React.useEffect(() => {
    setFormState(props.profile);
  }, [props.profile]);

  const submit = () => {
    props.save(formState);
  };

  React.useEffect(() => {
    props.get();
  }, []);

  return (
    <div className="flex space-x-12">
      <div className="w-2/3">
        <h1 className="text-xl pb-2">Din profil</h1>
        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <label className="block">
              <span className="text-gray-700">Namn</span>
              <input
                className="form-input block w-full p-2"
                placeholder="Ditt förnamn"
                defaultValue={formState.firstname}
                onKeyUp={(e) =>
                  setFormStateHelper("firstname", e.currentTarget.value)
                }
              />
            </label>
          </div>
          <div className="w-1/2">
            <label className="block">
              <span className="text-gray-700">Efternamn</span>
              <input
                className="form-input block w-full p-2"
                placeholder="Ditt efternamn"
                defaultValue={formState.lastname}
                onKeyUp={(e) =>
                  setFormStateHelper("lastname", e.currentTarget.value)
                }
              />
            </label>
          </div>
        </div>

        <div className="flex flex w-full space-x-4 mt-8">
          <div className="w-2/5">
            <label className="block">
              <span className="text-gray-700">Adress</span>
              <input
                className="form-input block w-full p-2"
                placeholder="Din adress"
                defaultValue={formState.address}
                onKeyUp={(e) =>
                  setFormStateHelper("address", e.currentTarget.value)
                }
              />
            </label>
          </div>
          <div className="w-1/5">
            <label className="block">
              <span className="text-gray-700">Postnummer</span>
              <input
                className="form-input block w-full p-2"
                placeholder="Ditt postnummer"
                defaultValue={formState.postal}
                onKeyUp={(e) =>
                  setFormStateHelper("postal", e.currentTarget.value)
                }
              />
            </label>
          </div>
          <div className="w-2/5">
            <label className="block">
              <span className="text-gray-700">Ort</span>
              <input
                className="form-input block w-full p-2"
                placeholder="Din ort"
                defaultValue={formState.city}
                onKeyUp={(e) =>
                  setFormStateHelper("city", e.currentTarget.value)
                }
              />
            </label>
          </div>
        </div>

        <div className="flex flex w-full space-x-4 mt-8">
          <div className="w-1/5">
            <label className="block">
              <span className="text-gray-700">Telefonnummer</span>
              <input
                className="form-input block w-full p-2"
                placeholder="Ditt telefonnummer"
                defaultValue={formState.phone}
                onKeyUp={(e) =>
                  setFormStateHelper("phone", e.currentTarget.value)
                }
              />
            </label>
          </div>
          <div className="w-2/5">
            <label className="block">
              <span className="text-gray-700">Epost-adress</span>
              <input
                disabled
                className="form-input block w-full p-2"
                placeholder="ditt.namn@epost.com"
                defaultValue={formState.email}
              />
            </label>
          </div>
          <div className="w-2/5">
            <label className="block">
              <span className="text-gray-700">Hemsida</span>
              <input
                className="form-input block w-full p-2"
                placeholder="https://"
                defaultValue={formState.homepage}
                onKeyUp={(e) =>
                  setFormStateHelper("homepage", e.currentTarget.value)
                }
              />
            </label>
          </div>
        </div>

        <Button className="pt-4" type="primary" onClick={(e) => submit()}>
          Spara
        </Button>
      </div>

      <div className="w-1/3">
        <h2>Din Profil</h2>

        <p>Här kan du redigera din profilinformation.</p>
        <p>
          Vi använder den informationen för att registrera rätt uppgifter om dig
          och dina hundar!
        </p>
      </div>
    </div>
  );
};

const state = (state) => {
  return {
    result: state.search.result,
    profile: state.profile,
  };
};

const dispatch = (dispatch) => {
  return {
    save: (data) => dispatch(saveProfileAsync(data)),
    get: () => dispatch(getProfileAsync()),
  };
};

export default connect(state, dispatch)(Profile);
