import sv from "date-fns/esm/locale/sv/index.js";
import { formatWithOptions } from "date-fns/fp";
import { Button } from "fragments/Button";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getOwnedDogsAsync, getProfileAsync } from "store/actions/actions";
import "./Home.css";

const Dogs = (props) => {
  React.useEffect(() => {
    props.getOwnedDogs();
  }, []);

  const { profile, ownedDogs } = props;

  const openExhibitionURL = (e) => {
    var url = e.currentTarget[e.currentTarget.selectedIndex].value;
    if (url === "") {
      return;
    }
    window.open(url, "_blank").focus();
  };

  const openTestURL = (url) => {
    window.open(url, "_blank").focus();
  };

  console.log("profile", profile);

  const { hasPaidThisYearsMembership } = profile;

  console.log("can register", hasPaidThisYearsMembership);
  console.log(profile);

  return (
    <>
      <h2 className="mb-2 text-xl tracking-tight text-slate-400 font-medium ">
        Mina hundar
      </h2>
      <div className="w-full rounded-lg bg-white pt-4 pb-4 pl-6 pr-6">
        {!ownedDogs || (ownedDogs.length === 0 && "inga hundar funna")}

        {ownedDogs && ownedDogs.length > 0 && (
          <table className="w-full table-auto ">
            <thead>
              <tr className="border-b">
                <th className="w-1/4 pb-2 text-left">Namn</th>
                <th className="w-1/4 pb-2 text-left hidden lg:block">Kön</th>
                <th className="w-1/4 pb-2 text-center">Anmäl utställning</th>
                <th className="w-1/4 pb-2 text-right">Anmäl jaktprov</th>
              </tr>
            </thead>
            <tbody>
              {ownedDogs.map((dog, i) => {
                const dogURL = `https://www.hittaalghund.se/dog.asp?Regnummer=${dog.Id}`;
                var css = "pt-4 pb-4";
                var linkCss = "text-blue-800 hover:fill-blue-400";

                if (i != ownedDogs.length - 1) {
                  css += " border-b mb-2 pb-2";
                }
                return (
                  <tr key={i} className={css}>
                    <td className="pt-2 pb-2 text-left">
                      <a
                        className={linkCss}
                        href={dogURL}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {dog.Name}
                      </a>
                    </td>
                    <td className="pt-2 pb-2 hidden lg:table-cell">
                      <span>{dog.Sex === "T" ? "Tik" : "Hane"}</span>
                    </td>
                    <td className="pt-2 pb-2 text-center">
                      <div className="flex justify-center">
                        <div className=" xl:w-40">
                          <select
                            disabled={
                              !hasPaidThisYearsMembership ? "disabled" : ""
                            }
                            onChange={openExhibitionURL}
                            className="form-select 
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            aria-label="Utställning"
                          >
                            <option selected>Välj</option>
                            {profile.exhibitions &&
                              profile.exhibitions.map((e, i) => {
                                console.log(e);
                                const fmt = formatWithOptions(
                                  { locale: sv },
                                  "yyyy-MM-dd"
                                );
                                return (
                                  <option key={i} value={e.registrationURL}>
                                    {e.name} {fmt(new Date(e.exhibitionDate))}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      <Button
                        disabled={!hasPaidThisYearsMembership}
                        size="xs"
                        onClick={(e) => {
                          e.preventDefault();
                          if (!hasPaidThisYearsMembership) {
                            return;
                          }
                          openTestURL(profile.clubData.hunterTestURL);
                        }}
                      >
                        Anmälan
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {/*ownedDogs &&
            ownedDogs.length > 0 &&
            ownedDogs.map((dog, i) => {
              const link = "/dog/" + encodeURIComponent(dog.Id);
              var css =
                "pl-6 pr-6 flex justify-end justify-items-center w-full text-blue-800 hover:fill-blue-400";
              if (i != ownedDogs.length - 1) {
                css += " border-b mb-2 pb-2";
              }
              return (
                <div key={dog.Id}>
                  <Link className={css} to={link}>
                    <span className="flex-grow">{dog.Name}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 flex-none"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </Link>
                </div>
              );
            })*/}
      </div>
    </>
  );
};

const state = (state) => {
  return {
    ownedDogs: state.dog.ownedDogs,
    result: state.search.result,
    profile: state.profile,
  };
};

const dispatch = (dispatch) => {
  return {
    getOwnedDogs: () => dispatch(getOwnedDogsAsync()),
    getProfile: () => dispatch(getProfileAsync()),
  };
};

export default connect(state, dispatch)(Dogs);
