import * as React from "react";

export const Button = (props) => {
  let css =
    "text-center rounded-md font-medium font-display focus:outline-none focus:shadow-outline shadow-md";
  //
  switch (props.type) {
    case "primary":
      css += " bg-blue-500 hover:bg-blue-600 text-white hover:bg-blue-600 ";
      break;
    default:
      css +=
        " bg-gray-200 hover:bg-blue-500 text-blue-700 hover:text-white border border-gray-300 hover:border-transparent rounded";
  }

  switch (props.size) {
    case "xs":
      css += "pl-2 pr-2 pt-1 pb-1 md:pl-2 md:pr-2 md:pt-1 md:pb-1";
      break;
    case "md":
      css += "pl-2 pr-2 pt-1 pb-1 md:pl-4 md:pr-4 md:pt-2 md:pb-2";
      break;
    default:
      // xl
      css += "pl-2 pr-2 pt-1 pb-1 md:pl-5 md:pr-5 md:pt-3 md:pb-3";
  }

  if (props.disabled) {
    css += " opacity-50 cursor-not-allowed";
  }
  console.log(css);

  let newProps = {};
  Object.assign(newProps, props);
  newProps.className = props.className + " " + css;

  return <button {...newProps}></button>;
};
